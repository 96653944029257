<template>
  <div class="mainBox">
    <div class="wrap">
      <div class="isLogo"><img :src="loadImg" alt="" ref="isImgRef" @click="isLogoClick"><span class="zi"></span>
      </div>
      <div class="rightBox">
        <div class="isNav" v-for="(item, index) in isList" :key="item.id" @click="toPageFn(item, index)">
          <!-- <div class="isNav"  v-for="(item,index) in isList" :key="index" :class="$route.name == item.name? 'active': ''" > -->
          <div>{{ item.title }}</div>
          <!-- <div class="isUnderLine" :style="{'opacity':$route.name == item.name || $route.name == item.rediret? 1 : 0}"></div> -->
        </div>
        <div class="downloadBtn" @click="downloadFn" style="cursor: pointer;">立即下载</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AllHead',
  data() {
    return {
      isList: [
        { title: '网站首页', id: 0 },
        { title: 'DLL修复工具', id: 1 },
        { title: 'DirectX修复工具', id: 2 },
      ],
      ishost: '',

      loadImg: ''

    }
  },
  created() {
    let currentHost = window.location.host
    if (this.newShowTextList.includes(currentHost)) {
      this.isList = [
        { title: '网站首页', id: 0 },
        { title: 'DLL修复工具', id: 1 },
        { title: 'DirectX修复工具', id: 2 },
      ]

    } else {
      this.isList = [
        { title: 'Directx类dII修复', id: 0 },
        { title: 'VC类dll修复', id: 1 },
        { title: 'Steam类dIl修复', id: 2 },
        { title: '.net修复', id: 3 },
      ]
    }
  },
  mounted() {
    this.ishost = window.location.host
    if (this.newShowTextList.includes(this.ishost)) {
      this.loadImg = require('../assets/img/logo1.png')
      this.$refs.isImgRef.classList.add('isImg1')
      this.$refs.isImgRef.classList.remove('isImg')

    } else {
      this.loadImg = require('../assets/img/logo.png')
      this.$refs.isImgRef.classList.add('isImg')
      this.$refs.isImgRef.classList.remove('isImg1')
    }
  },
  methods: {
    toPageFn(item, index) {
      console.log(item, index);
      if (this.newShowTextList.includes(this.ishost)) {
        if (item.id == 0) {
          window.location.href = 'http://soft.dlhykji.cn/'
        } else if (item.id == 1) {
          window.location.href = window.location.origin + '/dll' + window.location.search
        } else {
          console.log(item, index);
        }
      } else {
        return
      }
    },
    isLogoClick() {
      if (this.newShowTextList.includes(this.ishost)) {
        window.location.href = 'http://soft.dlhykji.cn/'
      }
    },
    toPage(v) {
      this.$router.push(this.isList[v].path)
    },
    goRoute(v) {
      let aaa = ['/', 'isAbout', 'lxUs']
      this.$router.push(aaa[v])
    }
  }
}
</script>
<style lang="scss" scoped>
.mainBox {
  width: 100%;
  height: 70px;

  // background-color: rgba(255,255,255,0.7);
  .wrap {
    width: 1180px;
    height: 100%;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: space-between;

    .isLogo {
      width: 600px;
      height: 100%;
      display: flex;
      justify-content: left;
      align-items: center;

      .isRouter {
        width: 200px;
        font-size: 14px;
        display: flex;
        justify-content: space-around;
        line-height: 28px;
        color: #5B5D61;
        font-weight: 600;

        .ishover {
          cursor: pointer;
        }

        // .ishover:hover {

        // }
      }

      img {
        width: 179px;
        height: 36px;
        object-fit: contain;
        cursor: pointer;
      }

      .isImg {
        width: 179px;
        height: 36px;
        object-fit: contain;
      }

      .isImg1 {
        width: 210px;
        height: 70px;
        object-fit: contain;
      }

      .zi {
        width: 160px;
        height: 28px;
        font-size: 14px;
        line-height: 28px;
        display: inline-block;
        margin-left: 10px;
        color: #5B5D61;
        font-weight: 600;
      }
    }

    .rightBox {
      width: 550px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .downloadBtn {
        width: 91px;
        height: 32px;
        background: #538BFF;
        border-radius: 4px;
        text-align: center;
        line-height: 32px;
        color: #fff;
      }

      .isNav {

        // width: 114px;
        height: 19px;
        font-size: 14px;
        font-weight: 600;
        color: #14142E;
        line-height: 16px;
        cursor: pointer;

        // cursor: pointer;
        // font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
        .isUnderLine {
          height: 3px;
          margin-top: 3px;
          background-color: var(--base_color);
        }

      }

      .active {
        color: var(--base_color);
      }
    }
  }

}
</style>